import { apiClient } from '../index';

/**
 * [getUsers description]
 * @return {[type]}          [description]
 */
function getUsers() {
  return apiClient.get('/users');
}

/**
 * [postUsers description]
 * @param {id} id   [description]
 * @param {string} data   [description]
 * @return {[type]}          [description]
 */
function updateUser(id, data) {
  return apiClient.patch('/users/' + id, data);
}

/**
 * [postUsers description]
 *  @param {string} data   [description]
 * @return {[type]}          [description]
 */
function createUser(data) {
  return apiClient.post('/users', data);
}

/**
 * [getOpeningHours description]
 * @param {string} region   [description]
 * @return {[type]}   [description]
 */
function getOpeningHours(region) {
  return apiClient.get('/settings/service/hours?region=' + region);
}
/**
 * [getOpeningHours description]
 * @return {[type]}   [description]
 */
function getOpeningHoursAll() {
  return apiClient.get('/settings/service/hours/region');
}

/**
 * [updateOpeningHours description]
 * @param {object} data          [description]
 * @return {[type]}          [description]
 */
function updateOpeningHours(data) {
  return apiClient.put('/settings/service/hours/region', data);
}

/**
 * [getAmountAllowAutoPay description]
 * @return {[type]}   [description]
 */
function getAmountAllowAutoPay() {
  return apiClient.get('/settings/chatbot/paymentThr');
}

/**
 * [getAmountAllowAutoPay description]
 * @param {object} id          [description]
 * @param {object} data          [description]
 * @return {[type]}          [description]
 */
function updateAmountAllowAutoPay(id, data) {
  return apiClient.patch('/settings/chatbot/paymentThr/' + id, data);
}

export default {
  getOpeningHours,
  updateOpeningHours,
  getUsers,
  createUser,
  updateUser,
  getOpeningHoursAll,
  getAmountAllowAutoPay,
  updateAmountAllowAutoPay,
};
