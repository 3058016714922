<template>
  <div class="DateSearch">
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          :label="$t('commonTimeSearch.inputLabel')"
          append-icon="fa-clock"
          readonly
          class="rounded-0"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <template>
        <div>
          <v-time-range-picker
            v-model="time"
            class="mt-10"
            format="24hr"
          />
        </div>
      </template>
      <v-row
        no-gutters
        style="background-color: var(--v-backgroundDashboard-base)"
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="resetTimes()"
        >
          {{ $t('commonForm.cancel') }}
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="isButtonDisabled"
          @click="sendDates(true)"
        >
          {{ $t('commonForm.ok') }}
        </v-btn>
      </v-row>
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment';
import VTimeRangePicker from '@/components/VTimeRangePicker';

export default {
  name: 'TimePicker',
  components: { VTimeRangePicker },
  props: {
    startTime: {
      type: String,
      default: '00:00',
    },
    endTime: {
      type: String,
      default: '00:00',
    },
  },
  data() {
    return {
      time: [],
      dateMenu: false,
      dates: null,
      start: this.startTime,
      end: this.endTime,
    };
  },
  computed: {
    locale: function() {
      return this.$i18n.locale;
    },
    dateRangeText: function() {
      if (this.start !== null && this.end !== null) {
        return moment(this.start, 'HH:mm').format('LT') + ' - ' + moment(this.end, 'HH:mm').format('LT');
      }
      return [];
    },
    isButtonDisabled: function() {
      return !(this.start !== null && this.end !== null);
    },
  },
  watch: {
    startTime(newValue) {
      this.start = newValue;
      this.time[0] = newValue;
    },
    endTime(newValue) {
      this.end = newValue;
      this.time[1] = newValue;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.time[0] = this.start;
      this.time[1] = this.end;
      this.sendDates();
    });
  },
  methods: {
    sendDates(click = false) {
      // console.log(moment(this.start, 'DD/MM/YYYY HH:mm:ss').toISOString());
      this.dateMenu = false;

      this.$emit('onchange', {
        start: moment(this.time[0], 'HH:mm').format(),
        end: moment(this.time[1], 'HH:mm').format(),
        confirmation: click,
      });
    },
    resetTimes() {
      this.dateMenu = false;
      this.time[0] = this.startTime;
      this.time[1] = this.endTime;
    },

  },
};
</script>

<style lang="scss" scoped>
.DateSearch {
  background: white;
}
.date-menu-style {
  box-shadow: none;
  border-radius: 0px;
}
</style>
