<template>
  <v-container fluid>
    <v-alert
      :value="snack"
      :type="snackColor"
    >
      {{ snackText }}
    </v-alert>
    <v-card v-if="authorize">
      <header-page-table
        :update-data="getUsers"
        :input-data="inputsToCreate"
        btntext="Crear Usuario"
        title="Configuración"
        @save="save($event)"
      />
      <table-user
        v-if="authorize"
        :headers="headers"
        :items="users"
        :input-data="inputsToEdit"
        @edit="edit($event)"
      />
    </v-card>

    <br />
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        class="pa-2"
      >
        <v-card
          class="mx-auto"
        >
          <v-toolbar
            color="indigo"
            dark
            flat
          >
            <v-toolbar-title>Configuración general</v-toolbar-title>

            <v-spacer />

            <v-btn @click="saveAmountAllow">
              Guardar
            </v-btn>
          </v-toolbar>
          <v-container>
            <CurrencyInput
              v-model="amountAllow.amountAllowedToAutoPay"
              label="Cantidad maxima permitida a ser otorgada de forma automática por Ecobot:"
            />
          </v-container>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="pa-2"
      >
        <v-card v-if="openingHours.sunday">
          <v-toolbar
            color="indigo"
            dark
            flat
            height="150px"
          >
            <v-toolbar-title>{{ openingHoursData.message }}</v-toolbar-title>

            <v-spacer />

            <v-select
              v-if="authorize"
              v-model="regionSelected"
              :items="regions"
              label="Seleccione archivo a importar"
              solo
              @change="changeRegion"
            />
          </v-toolbar>

          <v-form v-model="validFormHours">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong>Sunday</strong>
                  <time-picker
                    :start-time="moment(openingHours.sunday.start).format('HH:mm')"
                    :end-time="moment(openingHours.sunday.end).format('HH:mm')"
                    @onchange="setTime('sunday', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong> Monday </strong>
                  <time-picker
                    :start-time="moment(openingHours.monday.start).format('HH:mm')"
                    :end-time="moment(openingHours.monday.end).format('HH:mm')"
                    @onchange="setTime('monday', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong> Tuesday </strong>
                  <time-picker
                    :start-time="moment(openingHours.tuesday.start).format('HH:mm')"
                    :end-time="moment(openingHours.tuesday.end).format('HH:mm')"
                    @onchange="setTime('tuesday', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong> Wednesday </strong>
                  <time-picker
                    :start-time="
                      moment(openingHours.wednesday.start).format('HH:mm')
                    "
                    :end-time="moment(openingHours.wednesday.end).format('HH:mm')"
                    @onchange="setTime('wednesday', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong> Thursday </strong>
                  <time-picker
                    :start-time="
                      moment(openingHours.thursday.start).format('HH:mm')
                    "
                    :end-time="moment(openingHours.thursday.end).format('HH:mm')"
                    @onchange="setTime('thursday', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong> Friday </strong>
                  <time-picker
                    :start-time="moment(openingHours.friday.start).format('HH:mm')"
                    :end-time="moment(openingHours.friday.end).format('HH:mm')"
                    @onchange="setTime('friday', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <strong> Saturday </strong>
                  <time-picker
                    :start-time="
                      moment(openingHours.saturday.start).format('HH:mm')
                    "
                    :end-time="moment(openingHours.saturday.end).format('HH:mm')"
                    @onchange="setTime('saturday', $event)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TimePicker from '@/components/TimePicker.vue';

import importServiceSettings from '../services/modules/settings';
import dateFormatMixin from '@/mixins/dateFormat';
import moment from 'moment';
import HeaderPageTable from '@/components/HeaderPageTable.vue';
import TableUser from '@/components/TableUser.vue';
import CurrencyInput from '@/components/CurrencyInput.vue';

export default {
  name: 'Settings',
  components: {
    TimePicker, HeaderPageTable, TableUser, CurrencyInput,
  },
  mixins: [dateFormatMixin],
  data() {
    return {
      amountAllow: { amountAllowedToAutoPay: 0 },
      validFormHours: true,
      authorize: false,
      moment,
      snack: false,
      snackColor: 'success',
      snackText: '',
      search: '',
      regionSelected: 'CENTRO',
      regions: [
        'NORTE',
        'NOROESTE',
        'OCCIDENTE',
        'CENTRO',
        'SURESTE',
        'VALLE DE MEXICO',
      ],
      users: [],
      openingHoursData: {},
      inputData: [
        {
          cols: 4,
          text: 'Nombre(s)',
          value: '',
          id: 'firstName',
          type: 'text',
          rules: [(v) => !!v || 'Campo requerido'],
        },
        {
          cols: 8,
          text: 'Apellido(s)',
          value: '',
          id: 'lastName',
          type: 'text',
          rules: [(v) => !!v || 'Campo requerido'],
        },
        {
          cols: 6,
          text: 'Celular',
          value: '',
          id: 'cellphone',
          type: 'cellphone',
          rules: [],
        },
        {
          cols: 6,
          text: 'Email',
          value: '',
          id: 'email',
          type: 'text',
          rules: [
            (v) => !!v || 'El email es requerido',
            (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
          ],
        },
        {
          cols: 6,
          text: 'Rol',
          value: 'facilitador',
          id: 'roleType',
          type: 'select',
          items: ['facilitador', 'inase', 'superadmin'],
          rules: [(v) => !!v || 'El rol es requerido'],
        },
        {
          cols: 6,
          text: 'Región',
          value: '',
          id: 'region',
          type: 'select',
          items: [
            'NORTE',
            'NOROESTE',
            'OCCIDENTE',
            'CENTRO',
            'SURESTE',
            'VALLE DE MEXICO',
            'all',
          ],
          rules: [(v) => !!v || 'La region es requerida'],
        },
      ],
      headers: [
        // { text: 'id', value: 'id' },
        { text: '', value: 'more', sortable: false },
        { text: 'Nombre Completo', value: 'name' },
        { text: 'Nombre(s)', value: 'firstName' },
        { text: 'Apellido(s)', value: 'lastName' },
        { text: 'Celular', value: 'cellphone' },
        { text: 'Rol', value: 'roleType' },
        { text: 'Región', value: 'region' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'status' },
      ],
    };
  },
  computed: {
    openingHours() {
      if (!this.openingHoursData.data) {
        return {};
      }
      return this.openingHoursData.data.opening_hours;
    },
    userRole() {
      return JSON.parse(localStorage.getItem('user')).role;
    },
    inputsToCreate() {
      const inputs = [...this.inputData];
      inputs.push({
        cols: 6,
        text: 'Contraseña',
        value: '',
        id: 'password',
        type: 'password',
        rules: [(v) => !!v || 'La contraseña es requerida'],
      });
      inputs.push({
        cols: 6,
        text: 'Confirmar contraseña',
        value: '',
        id: 'confirmPassword',
        type: 'password',
        rules: [(v) => !!v || 'La contraseña es requerida'],
      });
      return inputs;
    },
    inputsToEdit() {
      const inputs = [...this.inputData];
      inputs.push({
        cols: 6,
        text: 'Contraseña',
        value: '',
        id: 'password',
        type: 'password',
        rules: [],
      });
      inputs.push({
        cols: 6,
        text: 'Confirmar contraseña',
        value: '',
        id: 'confirmPassword',
        type: 'password',
        rules: [],
      });
      return inputs;
    },
  },
  mounted() {
    this.authorize = this.userRole === 'superadmin';
    this.getUsers();
    this.getAmountAllow();
    if (this.authorize) {
      importServiceSettings
          .getOpeningHours(this.regionSelected)
          .then((response) => {
            this.openingHoursData = response.data;
          });
    } else {
      importServiceSettings.getOpeningHoursAll().then((response) => {
        this.openingHoursData = response.data;
      });
    }
  },
  methods: {
    getAmountAllow() {
      if (this.userRole !== 'superadmin') return;
      importServiceSettings.getAmountAllowAutoPay().then(({ data }) => {
        this.amountAllow = data.data || 0;
      });
    },
    saveAmountAllow() {
      if (this.userRole !== 'superadmin') return;
      importServiceSettings
          .updateAmountAllowAutoPay(this.amountAllow._id,
              { amountAllowedToAutoPay: Number(this.amountAllow.amountAllowedToAutoPay) })
          .then(({ data }) => {
            this.$vToastify.info('Cantidad permitida actualizada.');
          });
    },
    getUsers() {
      if (this.userRole !== 'superadmin') return;
      importServiceSettings.getUsers().then((response) => {
        this.users = response.data;
      });
    },
    async save(data) {
      if (data.password !== data.confirmPassword) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'La contraseña no coincide';
        return;
      }

      try {
        await importServiceSettings.createUser(data);
        this.snack = true;
        this.snackColor = 'success';
        this.snackText = 'Datos guardados';
        this.getUsers();
      } catch (error) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = error.response.data;
      }
      setTimeout(() => {
        this.snack = false;
      }, 3000);
    },

    async edit(data) {
      try {
        await importServiceSettings.updateUser(data.id, data);
        this.snack = true;
        this.snackColor = 'success';
        this.snackText = 'Data saved';
        this.getUsers();
      } catch (error) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Problem with data';
      }
      setTimeout(() => {
        this.snack = false;
      }, 3000);
    },
    changeRegion(region) {
      importServiceSettings.getOpeningHours(region).then((response) => {
        this.openingHoursData = response.data;
        // console.log(this.openingHoursData);
      });
    },
    setTime(day, time) {
      if (time.confirmation) {
        const data = {
          day: day,
          id: this.openingHoursData.data._id,
          region: this.openingHoursData.data.region,
          openingHours: {
            start: time.start,
            end: time.end,
          },
        };
        importServiceSettings.updateOpeningHours(data).then((response) => {
          this.openingHoursData = response.data;
        });
      }
    },
  },
};
</script>
